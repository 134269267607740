<template>
  <v-app style=" background-color: #FAFAFA;">
    <Toolbar />
    <v-main>
      <v-container fluid grid-list-xs pt-6>
        <v-layout row wrap>
          <v-flex xs10 lg11>
            <div>
               <h3>
               <v-avatar color="#f7b733" class="mr-5">
                  <v-icon dark>mdi-clipboard-remove-outline</v-icon>
                </v-avatar>
               รายการข้อมูลยกเว้น
              </h3>
            </div>
          </v-flex>
        </v-layout>
        <br />
        <v-form ref="form_searchdata"  lazy-validation>
          <!-- เงื่อนไขการค้นหาข้อมูล -->
          <v-layout row wrap justify-center align-center>
            <v-flex xs12 lg11 pl-4 pr-4 pb-2>
              <v-card class="rounded-lg">
                <v-card-text>
                  <h3>เงื่อนไขการค้นหา</h3>
                </v-card-text>
                <v-layout row wrap align-center pa-5>
                  <v-flex xs12 lg3 pa-4>
                    <!-- เงื่อนไขการค้นหา วันที่เอกสาร -->
                    <v-dialog
                      ref="dialogFromdate"
                      v-model="modalFromdate"
                      :return-value.sync="fromdate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          color="amber accent-4"
                          label="วันที่รับชำระ "
                          v-model="fromdate"
                          @click:prepend="modalFromdate = true"
                          prepend-icon="mdi-calendar-outline"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          clearable
                          class="my-hint-style"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fromdate"
                        scrollable
                        locale="th"
                        color="amber accent-4"
                        @input="fn_changeFromdate()"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click.stop="modalFromdate = false"
                        >
                          ปิด
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <!-- เงื่อนไขการค้นหา วันที่รับชำระถึง-->
                  <v-flex xs12 lg3 pa-4>
                    <v-dialog
                      ref="dialogTodate"
                      v-model="modalTodate"
                      :return-value.sync="todate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          color="amber accent-4"
                          label="ถึง"
                          v-model="todate"
                          prepend-icon="mdi-calendar-outline"
                          @click:prepend="modalTodate = true"
                          v-on="on"
                          outlined
                          dense
                          clearable
                          class="my-hint-style"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        scrollable
                        locale="th"
                        color="amber accent-4"
                        @input="$refs.dialogTodate.save(todate)"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click.stop="modalTodate = false"
                        >
                          ปิด
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-account-box-outline"
                      label="ชื่อลูกค้า"
                      color="amber accent-4"
                      v-model="customer_name"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-badge-account-horizontal-outline"
                      label="Customer Tax ID"
                      color="amber accent-4"
                      v-model="customer_taxID"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-clipboard-list-outline"
                      label="Invoice Number"
                      color="amber accent-4"
                      v-model="invoice_number_from"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-clipboard-list-outline"
                      label="To"
                      color="amber accent-4"
                      v-model="invoice_number_to"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-clipboard-text-outline "
                      label="Account Number"
                      color="amber accent-4"
                      v-model="account_number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-wallet-outline "
                      label="Air Waybill Number"
                      color="amber accent-4"
                      v-model="air_waybill"
                    ></v-text-field>
                  </v-flex>
                  <!-- ส่วนปุ่มการทำงาน -->
                  <v-flex xs12 lg12 text-right>
                    <!-- ปุ่มค้นหาข้อมูล -->
                    <v-btn
                      color="blue darken-3"
                      dark
                      class="ma-2"
                      @click="fn_getdataTotal"
                      ><b
                        ><v-icon class="mr-1">mdi-text-search</v-icon>
                        ค้นหาข้อมูล</b
                      ></v-btn
                    >
                    <!-- ปุ่ม Exportเอกสารแนบ-->
                    <v-dialog v-model="dialogImport" persistent max-width="490">
                      <!-- <template v-slot:activator="{ on, attrs }">
                        <v-btn color="teal darken-1" dark v-bind="attrs" v-on="on">
                          <v-icon class="mr-1" dark
                            >mdi-file-download-outline
                          </v-icon>
                          Import Excel
                        </v-btn>
                      </template> -->
                      <v-card>
                        <v-card-title>
                          <v-layout row wrap justify-center>
                            <v-flex xs10 lg10
                              ><span style="font-size:20px;" class="black--text"
                                >Import Excel</span
                              >
                            </v-flex>
                            <v-flex xs1 lg1>
                             <v-icon
                             :disabled="loadingData"
                                large
                                @click="
                                  (dialogImport = false), fn_resetDataExport()
                                "
                                color="red"
                                >mdi-close-circle </v-icon
                              >
                              </v-flex
                            >
                          </v-layout>
                        </v-card-title>
                        <v-divider></v-divider>
                        <br />
                        <v-card-text>
                          <v-form
                            ref="form_importExcel"
                            lazy-validation
                          >
                              <v-layout row wrap justify-center  align-center>
                                <!-- รูปแบบการนำออก -->
                              <v-flex xs10 lg10 pa-4>
                               <v-select
                               label="รูปแบบการนำออก"
                               prepend-icon="mdi-format-indent-increase "
                               :rules="[ v => !!v || 'กรุณาเลือก รูปแบบการนำออก']"
                               v-model="export_format"
                              :items="items"
                              dense
                              outlined
                              ></v-select>
                              </v-flex>
                              <!-- เดือนที่รับชำระ -->
                              <v-flex xs10 lg10 pa-4>
                               <v-dialog
                                  ref="dialogPaiddatefrom"
                                  v-model="modalPaiddatefrom"
                                  :return-value.sync="paiddatefrom"
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                    label="เดือนที่รับชำระ"
                                    prepend-icon="mdi-calendar-outline"
                                     :disabled="loadingData"
                                      color="amber accent-4"
                                      v-model="paiddatefrom"
                                      @click:prepend="modalPaiddatefrom = true"
                                      :rules="[ v => !!v || 'กรุณาเลือก เดือนที่รับชำระ']"
                                      v-on="on"
                                      readonly
                                      outlined
                                      dense
                                      clearable
                                      class="my-hint-style"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="paiddatefrom"
                                    scrollable
                                    locale="th"
                                    type="month"
                                    color="amber accent-4"
                                    @input="fn_changePaiddatefrom() ,fn_changeMonth() "
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click.stop="modalPaiddatefrom = false"
                                    >
                                      ปิด
                                    </v-btn>
                                  </v-date-picker>
                                </v-dialog>
                              </v-flex>
                              <!-- file input -->
                              <v-flex xs10 lg10 pa-4>
                              <v-file-input
                                :rules="[ v => !!v || 'กรุณาเลือก File input']"
                                v-model="files"
                                label="File input"
                                show-size
                                counter
                                outlined
                                dense
                                ></v-file-input>
                              </v-flex>
                            </v-layout>
                          </v-form>
                        </v-card-text>
                        <v-card-actions class="pa-7">
                          <v-spacer></v-spacer>
                          <v-btn
                            :disabled="loadingData"
                            color="red  lighten-1"
                            outlined
                            @click="
                              (dialogImport = false), fn_resetDataExport()
                            "
                          >
                            ยกเลิก
                          </v-btn>
                          <v-btn
                            dark
                            color="green accent-4"
                            @click="fn_submit()"
                             :loading="loadingData"
                          >
                            ยืนยัน
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- ปุ่มเคลียร์ข้อมูล -->
                    <v-btn
                      color="grey darken-2"
                      dark
                      outlined
                      class="ma-2"
                      @click="fn_clearData"
                      ><b
                        ><v-icon class="mr-1">mdi-notification-clear-all</v-icon
                        >เคลียร์ข้อมูล</b
                      ></v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>

        <!-- ส่วนของข้อมูลรวมทั้งหมด รายการภาษีหัก ณ ที่จ่าย -->
        <v-layout row wrap justify-center align-center pt-2 >
          <v-flex xs12 lg2 pa-3 align-self-center>
            <v-card class="rounded-lg">
              <v-card-text class="text-center">
                Total
              </v-card-text>
              <v-card-text class="text-center">
                <h3 class="black--text">{{ total_data }}</h3>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 lg2 pa-3>
            <v-card class="rounded-lg">
              <v-card-text class="text-center">
                <!-- จำนวนเงินที่ต้องคำนวณภาษี -->
                Grand Total Amount
              </v-card-text>
              <v-card-text class="text-center">
                <h3 class="black--text">{{ total_basis_amount }}</h3>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 lg2 pa-3>
            <v-card class="rounded-lg">
              <v-card-text class="text-center">
                <!-- ภาษีหัก ณ ที่จ่าย -->
                Total WHT 1%
              </v-card-text>
              <v-card-text class="text-center">
                <h3 class="black--text">{{ total_wht1 }}</h3>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 lg2 pa-3>
            <v-card class="rounded-lg">
              <v-card-text class="text-center">
                Total WHT 3%
              </v-card-text>
              <v-card-text class="text-center">
                <h3 class="black--text">{{ total_wht3 }}</h3>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 lg3 pa-3>
            <v-card class="rounded-lg">
              <v-card-text class="text-center">
                <!-- เงินที่เข้าบัญชี -->
                Total Received Amount
              </v-card-text>
              <v-card-text class="text-center">
                <h3 class="black--text">{{ total_actual_amount }}</h3>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <!-- ปุ่มเเสดงรายการลบเอกสาร
        <v-layout row wrap justify-center v-if="showstatusDelete">
          <v-flex pl-2 pr-2
            xs12
            lg11
            sm11
            md11
            style="text-align: left; margin-left: 15px"
          >
            <v-btn color="error">
              <v-icon left>mdi-delete-variant</v-icon>
              ลบเอกสาร {{numDelete}}  รายการ
            </v-btn>
          </v-flex>
        </v-layout> -->
        <v-layout row wrap justify-center align-center pt-3 pb-8>
           <v-flex xs12 lg11 pl-4 pr-4 pb-4>
             <div v-if="exceptStatus === false">
               <v-btn color="warning" @click="exceptStatus=true" class="ma-2" v-if="DHL_Username === 'testonebilling'" > Exclude ข้อมูล</v-btn>
                <!-- <v-btn color="green accent-4" dark class="ma-2" @click="opendialog_excludebyexcel = true">
                      <b>
                        <v-icon class="mr-1">mdi-file-excel-outline</v-icon> Exclude ด้วย Excel
                      </b>
                </v-btn> -->
             </div>
              <div v-else>
               <v-btn color="error" @click="fn_opendialogExcludeByApi()" class="ma-2">{{ exceptData.length || 0}} รายการ</v-btn>
               <v-btn color="info" @click="exceptStatus=false, fn_clearexceptData()" class="ma-2">ยกเลิก</v-btn>
             </div>
           </v-flex>
          <v-flex xs12 lg11 pl-4 pr-4>
            <v-card class="rounded-lg">
              <v-data-table
                :headers="headers"
                :items="desserts"
                item-key="document_id"
                hide-default-footer
                :items-per-page="perpage"
                :page.sync="page"
                @page-count="pageCount = $event"
                v-model="exceptData"
                :show-select="exceptStatus"
              >
                <template v-slot:item="props">
                    <tr >
                       <td class="text-center" v-show="exceptStatus">
                          <v-checkbox v-model="exceptData" :value="props.item" />
                        </td>
                      <td class="text-center" style="font-size: 13px">
                        {{ props.item.number }}
                        <!-- {{ index + 1 + perpage * (page - 1) }} -->
                      </td>
                      <td class="text-center" style="font-size: 13px">
                        <div v-if="props.item.paid_date == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ getDate(props.item.paid_date.split("T")[0]) }}
                        </div>
                      </td>
                      <td style="font-size: 13px">
                        <div v-if="props.item.document_id == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ props.item.document_id }}
                        </div>
                      </td>
                      <td style="font-size: 13px">
                        <div v-if="props.item.awb_no == null" class="text-center">
                          <span>-</span>
                        </div>
                        <div v-else>
                          <div v-if="props.item.awb_no.length == 1">
                            <span
                              v-for="(num_awb, index) in props.item.awb_no"
                              :key="index"
                              >{{ num_awb }}</span
                            >
                          </div>
                          <div v-else-if="props.item.awb_no.length >= 1">
                            <div class="text-center">-</div>
                          </div>
                        </div>
                      </td>
                      <td style="font-size: 13px">
                        <div v-if="props.item.customer_account == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ props.item.customer_account }}
                        </div>
                      </td>
                      <td style="font-size: 13px">
                        <div v-if="props.item.customer_name == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ props.item.customer_name }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="props.item.basis_amount == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ props.item.basis_amount.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="props.item.total_vat == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ props.item.total_vat.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="props.item.total_amount == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ props.item.total_amount.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="props.item.wht3_basis == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ props.item.wht3_basis.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="props.item.wht3 == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ props.item.wht3.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="props.item.wht1_basis == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ props.item.wht1_basis.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="props.item.wht1 == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ props.item.wht1.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="props.item.actual_amount == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ props.item.actual_amount.toLocaleString() }}
                        </div>
                      </td>
                    </tr>
                </template>
              </v-data-table>
              <br />
              <v-pagination
                :total-visible="10"
                v-model="page"
                :length="pageCount"
                color="#f7b733"
                @input="fn_getdataTotal"
                circle
              ></v-pagination>
              <br />
            </v-card>
          </v-flex>
        </v-layout>
        <!-- ส่วนของปุ่มสถานะกำลังดาวน์โหลด -->
        <v-overlay :value="loadingStatus">
          <div class="text-center">กำลังดาวน์โหลดข้อมูล ...</div>
          <v-progress-circular
            indeterminate
            size="64"
            class="ml-9 mt-3"
          ></v-progress-circular>
        </v-overlay>
        <div>
           <dialogExcludeByAPI :show="opendialog_excludebyinvoice" :datainvoice="exceptDataApi" @closedialog="opendialog_excludebyinvoice = false, fn_clearexceptData()" @reloaddata="fn_getdataTotal(), exceptStatus = false"/>
           <dialogExcludeByExcel :show="opendialog_excludebyexcel" @closedialog="opendialog_excludebyexcel = false, fn_clearexceptData()" @reloaddata="fn_getdataTotal(), exceptStatus = false"/>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Toolbar from '../components/toolbar'
import Swal2 from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { format } from 'date-fns'
const dialogExcludeByAPI = () => import('../components/excludeInvoice/dialog-excludebyapi.vue')
const dialogExcludeByExcel = () => import('../components/excludeInvoice/dialog-excludebyExcel.vue')
const Toast = Swal2.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
})
export default {
  components: {
    Toolbar, dialogExcludeByAPI, dialogExcludeByExcel
  },
  data: function () {
    return {
      opendialog_excludebyexcel: false,
      opendialog_excludebyinvoice: false,
      exceptDataApi: [],
      exceptData: [],
      exceptStatus: false,
      files: [],
      numDelete: '',
      export_format: '',
      selectItem: [],
      selectItemAll: false,
      loadingData: false,
      showstatusDelete: false,
      account_number: '',
      invoice_number_from: '',
      invoice_number_to: '',
      air_waybill: '',
      total_number: '',
      total_data: '',
      total_basis_amount: '',
      total_actual_amount: '',
      total_wht1: '',
      total_wht3: '',
      customer_name: '',
      customer_taxID: '',
      status: '',
      page: 1,
      perpage: 50,
      fromdate: '',
      modalFromdate: false,
      todate: '',
      modalTodate: false,
      paiddatefrom: '',
      paid_date_from: '',
      paid_date_to: '',
      modalPaiddatefrom: false,
      loadingStatus: false,
      dialogImport: false,
      items: ['Account ID', ' Invoice Number'],
      headers: [
        {
          text: 'ลำดับ',
          align: 'start',
          sortable: false,
          value: 'number',
          class: 'black--text'
        },
        {
          text: 'วันที่รับชำระ',
          value: 'paid_date',
          sortable: false,
          align: 'center',
          class: 'black--text'
        },
        {
          text: 'เลขที่อ้างอิง',
          value: 'document_id',
          sortable: false,
          align: 'center',
          class: 'black--text'
        },
        {
          text: 'AWB No.',
          value: 'awb_no',
          sortable: false,
          align: 'center',
          class: 'black--text'
        },
        {
          text: 'รหัสลูกค้า',
          value: 'customer_account',
          sortable: false,
          align: 'center',
          class: 'black--text'
        },
        {
          text: 'ชื่อลูกค้า',
          value: 'customer_name',
          sortable: false,
          align: 'center',
          class: 'black--text'
        },
        {
          text: 'มูลค่าก่อนคำนวณภาษี',
          value: 'basis_amount',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'มูลค่าภาษี',
          value: 'total_vat',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'มูลค่ารวมภาษี',
          value: 'total_amount',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'มูลค่าที่นำมาคิด 3%',
          value: 'wht3_basis',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'มูลค่าภาษี 3%',
          value: 'wht3',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'มูลค่าที่นำมาคิด 1%',
          value: 'wht1_basis',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'มูลค่าภาษี 1%',
          value: 'wht1',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'จำนวนเงินได้รับจริง',
          value: 'actual_amount',
          sortable: false,
          align: 'right',
          class: 'black--text'
        }
      ],
      desserts: []
    }
  },
  created () {
    // set defult ใน v-model วันที่รับชำระ
    // this.fromdate = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000)
    //   .toISOString()
    //   .substr(0, 10)
    // this.todate = new Date(Date.now()).toISOString().substr(0, 10)
    // เซ็ตค่าวันที่รับเอกสาร Export PND.53
    this.paiddatefrom = new Date(Date.now()).toISOString().substr(0, 7)
  },
  mounted () {
    this.fn_getdataTotal()
    this.fn_changeMonth()
  },
  methods: {
    // ฟังชันเคลียร์ค่าที่เลือก
    fn_clearexceptData () {
      this.exceptData.splice(0, this.exceptData.length)
      this.exceptDataApi.splice(0, this.exceptDataApi.length)
    },

    // ฟังชันจัดข้อมูลสำหรับเตรียมยิง API และ เปิด Dialog
    fn_opendialogExcludeByApi () {
      if (this.exceptData.length <= 0) {
        Toast.fire({
          icon: 'warning',
          title: 'กรุณาเลือก Invoice ที่ต้องการ Exclude'
        })
      } else {
        this.exceptDataApi = []
        this.exceptData.forEach(element => {
          element.status = 'Waiting'
          element.result = 'Ready To Exclude Invoice'
          element.progress = 0
          this.exceptDataApi.push(element)
        })
        this.opendialog_excludebyinvoice = true
      }
    },

    // ฟังชันเปลี่ยนเเปลงค่า วันที่เริ่มต้นจ่าย
    fn_changeFromdate () {
      this.$refs.dialogFromdate.save(this.fromdate)
    },
    fn_changePaiddatefrom () {
      this.$refs.dialogPaiddatefrom.save(this.paiddatefrom)
    },
    fn_selectMonth (month, year) {
      this.paid_date_from = year + '-' + month + '-01'
      var dayEnd = new Date(parseInt(year), parseInt(month), 0).getDate()
      this.paid_date_to = year + '-' + month + '-' + dayEnd
    },
    fn_changeMonth () {
      var selectmonth = this.paiddatefrom.split('-')
      this.fn_selectMonth(selectmonth[1], selectmonth[0])
    },
    // ฟังก์ชัน format วันที
    getDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    // ฟังก์ชันค้นข้อมูลทั้งหมด
    fn_getdataTotal: async function () {
      if (this.$refs.form_searchdata.validate()) {
        this.desserts = []
        const payload = {
          customer_tax_id: this.customer_taxID,
          customername: this.customer_name,
          paid_date_from: this._fromdate,
          paid_date_to: this._todate,
          page: this.page,
          perpage: this.perpage,
          customer_account: this.account_number,
          document_id_from: this.invoice_number_from,
          document_id_to: this.invoice_number_to,
          awb_no: this.air_waybill
        }
        // console.log(payload)
        const getAccesstoken = await this.$store.dispatch('action_request_accesstoken')
        if (getAccesstoken.status === 'ER') {
          Toast.fire({
            icon: 'error',
            title: 'Session Timeout'
          })
          this.$router.push('/logout')
        } else {
          var headers = { Authorization: getAccesstoken.token }
          this.loadingStatus = true
          // var AccessToken = JSON.parse(localStorage.DHL_Access).data.one_access_token
          // var headers = { Authorization: '' + AccessToken }

          this.axios
            .post(process.env.VUE_APP_SERVICE_API + '/witholdingtax/except', payload, { headers })
            .then(res => {
              if (res.data.status === 'OK' && res.data.data.length > 0) {
                var indexdata = 0
                res.data.data.forEach(element => {
                  indexdata = indexdata + 1
                  element.number = indexdata + this.perpage * (this.page - 1)
                  this.desserts.push(element)
                })
                // this.desserts = res.data.data
                this.total_number = res.data.total_data

                // ค่าผลรวม Total
                if (res.data.total_data == null) {
                  this.total_data = '-'
                  console.log('Total : null')
                } else {
                  this.total_data = parseInt(
                    res.data.total_data
                  ).toLocaleString()
                }

                // ค่า Grand Total Amount
                if (res.data.total_basis_amount == null) {
                  this.total_basis_amount = '-'
                  console.log(' Grand Total Amount: null')
                } else {
                  this.total_basis_amount = res.data.total_basis_amount.toLocaleString(
                    'en-US',
                    { maximumFractionDigits: 2 }
                  )
                }

                // ค่า Total WHT 1%
                if (res.data.total_wht1 == null) {
                  this.total_wht1 = '-'
                  console.log('Total WHT 1% : null')
                } else {
                  this.total_wht1 = res.data.total_wht1.toLocaleString('en-US', {
                    maximumFractionDigits: 2
                  })
                }

                // ค่า Total WHT 3%
                if (res.data.total_wht3 == null) {
                  this.total_wht3 = '-'
                  console.log('Total WHT 3% : null')
                } else {
                  this.total_wht3 = res.data.total_wht3.toLocaleString('en-US', {
                    maximumFractionDigits: 2
                  })
                }

                // ค่า Total Received Amount
                if (res.data.total_actual_amount == null) {
                  this.total_actual_amount = '-'
                  console.log('Total Received Amount : null')
                } else {
                  this.total_actual_amount = res.data.total_actual_amount.toLocaleString(
                    'en-US',
                    { maximumFractionDigits: 2 }
                  )
                }

                // console.log(this.desserts)
                this.loadingStatus = false
              } else if (res.data.data.length === 0) {
                this.desserts = []
                this.total_data = '-'
                this.total_basis_amount = '-'
                this.total_wht1 = '-'
                this.total_wht3 = '-'
                this.total_actual_amount = '-'
                this.loadingStatus = false
                Toast.fire({
                  icon: 'warning',
                  title: 'ไม่พบข้อมูลในระบบ'
                })
              }
            })
            .catch(err => {
              this.loadingStatus = false
              this.total_data = '-'
              this.total_basis_amount = '-'
              this.total_wht1 = '-'
              this.total_wht3 = '-'
              this.total_actual_amount = '-'
              console.log(err)
              Toast.fire({
                icon: 'error',
                title: String(err)
              })
            })
        }
      } else {
        this.fn_clearData()
        this.loadingStatus = false
      }
      // this.fn_clearData()
    },
    fn_downloadExample: function () {
      this.dialogImport = true
    },
    fn_selectFileImport: function () {
      this.dialogImport = true
    },
    // funtion export PDN 53
    fn_submit: function () {
      if (this.$refs.form_importExcel.validate()) {
        const payload = {
          paid_date_from: this.paid_date_from,
          paid_date_to: this.paid_date_to
        }
        // console.log(payload)
        this.dialogImport = true
        this.loadingData = true
        var AccessToken = JSON.parse(localStorage.DHL_Access).data.one_access_token
        var headers = { Authorization: 'Authorization ' + AccessToken }
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_API +
              '/witholdingtax/exportincometaxreturn',
            payload,
            { headers },
            { responseType: 'blob' }
          )
          .then(res => {
            console.log(res)
            if (res.status === 200) {
              this.dialogImport = false
              this.loadingStatus = false
              this.loadingData = false
              const url = URL.createObjectURL(
                new Blob([res.data], {
                  type: 'application/vnd.ms-excel'
                })
              )
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', payload)
              document.body.appendChild(link)
              link.download = 'เอกสารแนบ ภ.ง.ด.53.xlsx'
              link.click()
              this.fn_resetDataExport()
            } else {
              this.dialogImport = false
              this.loadingData = false

              Toast.fire({
                icon: 'error',
                title: 'ไม่พบไฟล์เอกสารในระบบฐานข้อมูล'
              })
            }
          })
          .catch(err => {
            console.log(err)
            this.dialogImport = false
            this.loadingData = false

            Toast.fire({
              icon: 'error',
              title: 'ไม่สามารถเชื่อมต่อ API ดาวน์โหลดไฟล์ ได้'
            })
          })
      }
    },
    // ฟังก์ชันรีเซตค่า Validate
    fn_resetDataExport: function () {
      this.$refs.form_importExcel.resetValidation()
      this.paiddatefrom = new Date(Date.now()).toISOString().substr(0, 7)
    },
    fn_selectOneData: function (item) {
      console.log(item)
      this.showstatusDelete = true
      this.numDelete = item.document_id

      // for (var i in this.desserts) {
      //   if (this.desserts[i].selectItem === true) {
      //     this.selectItem = this.selectItem + 1
      //     console.log(this.desserts[i].selectItem)
      //   }
      // }
      // if (this.selectItem === this.desserts.length) {
      //   this.selectItemAll = true
      // } else {
      //   this.selectItemAll = false
      // }
    },
    // ฟังก์ชันเคลียร์ข้อมูล
    fn_clearData: function () {
      this.account_number = ''
      this.invoice_number_from = ''
      this.invoice_number_to = ''
      this.air_waybill = ''
      this.total_number = ''
      this.total_data = ''
      this.total_basis_amount = ''
      this.total_actual_amount = ''
      this.total_wht1 = ''
      this.total_wht3 = ''
      this.fromdate = ''
      this.todate = ''
      this.customer_name = ''
      this.desserts = []
      this.paiddatefrom = ''
    }
  },
  computed: {
    ...mapGetters(['getter_dhl_profile']),
    pageCount: {
      get () {
        const l = this.total_number
        const s = this.perpage
        return Math.ceil(l / s)
      },
      set (newName) {
        return newName
      }
    },
    _fromdate: {
      get () {
        if (this.fromdate === '' || this.fromdate === null) {
          return ''
        } else {
          return format(new Date(this.fromdate), 'yyyy-MM-dd')
        }
      }
    },
    _todate: {
      get () {
        if (this.todate === '' || this.todate === null) {
          return ''
        } else {
          return format(new Date(this.todate), 'yyyy-MM-dd')
        }
      }
    },
    DHL_Username: {
      get () {
        return localStorage.getItem('DHL_Username')
      }
    }
  }
}
</script>
